import React from 'react';
import '../privacy/privacy.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1>Gizlilik Sözleşmesi</h1>
        <p>
          <strong>Gizlilik ve Güvenlik</strong>
        </p>
        <p>
          Bu gizlilik politikası, uygulamamızın kullanıcılarından topladığı,
          kullandığı, sakladığı ve açıkladığı bilgileri yönetir.
        </p>
        <p>
          <strong>Kişisel Bilgiler</strong>
        </p>
        <p>
          Uygulamamızı kullanırken, sizinle iletişime geçebilmek için kişisel
          bilgilerinizi toplayabiliriz. Bu bilgiler arasında adınız, e-posta
          adresiniz ve telefon numaranız bulunabilir.
        </p>
        <p>
          <strong>Toplanan Veriler</strong>
        </p>
        <p>
          Uygulama kullanımınız sırasında, cihaz bilgileri, log verileri ve
          kullanım istatistikleri gibi bazı bilgileri otomatik olarak
          toplayabiliriz.
        </p>
        <p>
          <strong>Bilgi Kullanımı</strong>
        </p>
        <p>
          Toplanan bilgileri, uygulamamızı geliştirmek, kişiselleştirmek ve
          kullanıcı deneyimini iyileştirmek için kullanabiliriz.
        </p>
        <p>
          <strong>Bilgi Paylaşımı</strong>
        </p>
        <p>
          Kişisel bilgilerinizi üçüncü şahıslarla paylaşmayız, satmayız veya
          kiralamayız. Ancak, yasaların gerektirdiği durumlarda bu bilgileri
          paylaşabiliriz.
        </p>
        <p>
          <strong>Gizlilik Politikası Değişiklikleri</strong>
        </p>
        <p>
          Bu gizlilik politikasını zaman zaman güncelleyebiliriz. Güncellemeleri
          bu sayfada yayınlayarak sizi bilgilendireceğiz.
        </p>
        <p>
          <strong>İletişim</strong>
        </p>
        <p>
          Gizlilik politikamızla ilgili herhangi bir sorunuz varsa, lütfen bize
          ulaşın.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
