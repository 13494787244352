import React from "react";
import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Content from "./components/content/Content";
import Sidebar from "./components/sidebar/Sidebar";
import Warehouses from "./components/warehouses/Warehouses";
import Employees from "./components/employees/Employees";
import Auth from "./components/auth/Auth";
import { UserProvider, useAuth } from "./context/UserContext";
import WarehouseDetail from "./components/warehouse_detail/warehouse_detail";
import PrivacyPolicy from "./components/privacy/privacy"; // Import the PrivacyPolicy component

const App: React.FC = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Content />} />
            <Route path="warehouses" element={<Warehouses />} />
            <Route path="warehouses/:id" element={<WarehouseDetail />} />
            <Route path="employees" element={<Employees />} />
          </Route>
          <Route path="/auth" element={<Auth />} />
          <Route path="/privacy" element={<PrivacyPolicy />} /> {/* Add the privacy policy route */}
        </Routes>
      </Router>
    </UserProvider>
  );
};

const Layout: React.FC = () => {
  return (
    <div className="app">
      <Sidebar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="warehouses" element={<Warehouses />} />
          <Route path="warehouses/:id" element={<WarehouseDetail />} />
          <Route path="employees" element={<Employees />} />
        </Routes>
      </div>
    </div>
  );
};

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  return user ? <>{children}</> : <Navigate to="/auth" />;
};

export default App;
